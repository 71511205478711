import React, { Fragment } from 'react';
import "./WorkFlowSalesOrderTrackingSurveyForm.scss";

//Contexts
import { FormLabel, FlexRow, onFieldChange, toasty, FlexCenterRow } from '../common/forms/FormElements';
import CommonContext, { ApiRoutes, AppNavPaths } from '../Common';
import {
    questionInputTypes,
    questionExpectedValueRelationship,
    questionEpectedValueType,
    SalesOrderTrackingSurveyDTO
} from '../formsAndSurveys/FormsAndSurveys';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faSave,
    faTimes,
    faTasks,
    faPaperPlane,
    faCamera,
    faFilePdf,
    faRepeat
} from '@fortawesome/free-solid-svg-icons'
import {
    AppPageForm
} from '../common/forms/FormElements';
import { LinearProgress } from '@material-ui/core';
import { util } from '../Util';
import {
    Button,
    FormGroup,
    Container,
    Row,
    Col,
    Input,
    Label,
    InputGroup,
    InputGroupText,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Modal
} from 'reactstrap';
import { Prompt, withRouter } from 'react-router-dom';
import { handleFormSaveError } from '../common/forms/ValidationError';
import authService from '../api-authorization/AuthorizeService';
import { getUserProfile } from '../common/UserProfile';
import FormErrorResponseDisplay from '../common/forms/FormErrorResponseDisplay';
import ValidatedSelect from '../common/forms/ValidatedSelect';
import SalesOrderTrackingSlideForm from './SalesOrderTrackingSlideForm';
import Webcam from "react-webcam";
import { v4 as uuid } from 'uuid';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/dist/styles.css";
import { Can } from '../Can';

class FormFiller extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);

        this.formRef = React.createRef();
        this.webCamRef = React.createRef();
        this.salesOrderTrackingSlideFormRef = React.createRef();
        this.saveButtonRef = React.createRef();

        const abortController = new AbortController();

        this.state = {
            formopened: false,
            formvalidated: false,
            loading: true,
            saving: false,
            perms: [],
            errorresponse: {},
            fillForm: new SalesOrderTrackingSurveyDTO(),
            dirty: false,
            getPicutre: false,
            activeImageQuestion: null,
            lbIsOpen: false,
            lbImgIndex: 0,
            lbImages: [],
            salesOrderTrackingId: null,
            workCenterId: 0,
            forSalesOrderTracking: false,
            shippingId: null,
            forShipping: false,
            noCamera: false,
            videoConstraints: {
                width: 1920,
                height: 1080
            },
            deviceIds: [],
            currentDeviceId: 0,
            formStatuses: [],
            chosenSurveyStatus: 0,
            abortController: abortController
        }

        this.onChange = this.onChange.bind(this);
        this.handleSaveError = this.handleSaveError.bind(this);
    }

    componentDidMount = async () => {

        if (this.props.location.pathname.includes(AppNavPaths.SalesOrderTrackingSurvey)) {
            this.setState({
                forSalesOrderTracking: true
            });
        }

        if (this.props.location.pathname.includes(AppNavPaths.Shipping)) {
            this.setState({
                forShipping: true
            });
        }

        this._subscription = authService.subscribe(async () => await this.populateState());
        await this.populateState();
    }

    componentWillUnmount = async () => {
        const { abortController } = { ...this.state };

        abortController.abort();

        await authService.unsubscribe(this._subscription);

        let { stream, video } = { ...this.webCamRef.current };

        let currentTracks = stream?.getTracks();

        if (currentTracks) {
            currentTracks.forEach(track => {
                track.stop();
                stream.removeTrack(track);
            });
        }
        if (video) {
            video.pause();
            video.src = "";
            video.srcObject = null;
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps && (
            (this.props.match.params.wcid !== (prevProps.match.params ?? {}).wcid)
            || (this.props.match.params.sotsid !== (prevProps.match.params ?? {}).sotsid)
            || (this.props.match.params.sotid !== (prevProps.match.params ?? {}).sotid)
            || (this.props.match.params.shpid !== (prevProps.match.params ?? {}).shpid)
        )
        ) {
            this.populateState();
        }
    }

    async populateState() {
        await this.setState({
            loading: true
        });

        const isAuthenticated = await authService.isAuthenticated();

        if (!!isAuthenticated) {
            let { wcid, sotid, sotsid, shpid } = { ...this.props.match.params };
            let { abortController } = { ...this.state };
            const { userPermissions } = await getUserProfile();

            let dto = {
                WorkCenterId: wcid,
                WorkCenterSurveyId: 0,
                SalesOrderTrackingId: sotsid
            }
            if (!abortController.signal.aborted) {
                var [surveyResponse, surveyCompletionStatuses] = await Promise.all([
                    util.fetch.post(ApiRoutes.workCenter.workCenterSurvey(), dto, util.fetch.format.json, { signal: abortController.signal }),
                    util.fetch.js(ApiRoutes.typeAheads.surveyCompletionStatuses(), { signal: abortController.signal }),
                ]);

                var fillForm;
                var completionStatuses;

                //handle any issues fetching data
                if (!!surveyResponse.error) {
                    //handle erroneous links entered by the user
                    let serviceresponse = {
                        title: 'server error',
                        errors: {
                            exception: [
                                surveyResponse.error
                            ]
                        }
                    }
                    this.displaysaveerrors(serviceresponse);
                    return false;
                } else {
                    fillForm = await surveyResponse;
                }

                let noCamera = true;

                if (navigator.mediaDevices) {

                    let devices = await navigator.mediaDevices.enumerateDevices();
                    let videoDevices = devices.filter((d) => d.kind == "videoinput");

                    var component = this;

                    navigator.permissions.query(
                        { name: 'camera' }
                    ).then(function (permissionStatus) {
                        let { videoConstraints, deviceIds } = { ...component.state };

                        if (videoDevices.length) {
                            noCamera = false;

                            deviceIds.push(videoDevices[0].deviceId);
                            videoConstraints.deviceId = permissionStatus.state == 'granted' ? { exact: deviceIds[0] } : deviceIds[0];

                            if (videoDevices.length > 1) {
                                deviceIds.push(videoDevices[1].deviceId);
                            }

                            component.setState({
                                noCamera: noCamera,
                                videoConstraints: videoConstraints,
                                deviceIds: deviceIds
                            });

                        }

                        if (permissionStatus.state == 'granted') {
                            if (devices.length > 1) {
                                component.switchWhichCamera(); //try and use a different camera to prompt again
                            }
                        }
                        permissionStatus.onchange = async function () {
                            if (this.state == 'granted') {
                                let nDeviceIds = [];
                                let pDevices = await navigator.mediaDevices.enumerateDevices();
                                let pVideoDevices = pDevices.filter((d) => d.kind == "videoinput");

                                nDeviceIds.push(pVideoDevices[0].deviceId);
                                if (pVideoDevices.length > 1) {
                                    nDeviceIds.push(pVideoDevices[1].deviceId);
                                    component.setState({
                                        deviceIds: nDeviceIds
                                    });
                                }
                            }
                        }

                    })
                    
                }

                try {
                    completionStatuses = surveyCompletionStatuses.map((scs) => {
                        return { value: Number(scs.value), label: scs.label };
                    });
                } catch (err) {
                    console.log(err);
                    completionStatuses = [];
                }

                await this.setState({
                    perms: userPermissions,
                    loading: false,
                    originaldata: JSON.parse(JSON.stringify(fillForm)),
                    fillForm: fillForm,
                    salesOrderTrackingId: sotid,
                    WorkCenterId: wcid,
                    shippingId: shpid,
                    formStatuses: completionStatuses,
                    chosenSurveyStatus: fillForm.salesOrderTrackingSurveyCompletionStatusId
                });
            }
        }
    }

    onChange = onFieldChange;

    clearSaving = () => this.setState({ saving: false });
    notifySuccess = () => toasty.success('Form Saved', `Form saved successfully.`);
    notifyError = (message) => toasty.error('Save Unsuccessful', message);
    handleSaveError = (err) => handleFormSaveError(this, err);
    displaySaveErrors = (response) => this.setState({ errorResponse: response });
    clearSaveErrors = () => this.setState({ errorResponse: {} });

    onCloseClicked = () => {
        let { wcid, shpid } = { ...this.props.match.params };
        let { fillForm, forSalesOrderTracking, forShipping } = { ...this.state };

        if (forSalesOrderTracking) {
            this.props.history.push(`${AppNavPaths.SalesOrderTracking}/${fillForm.salesOrderTrackingId}`);
        } else {
            if (forShipping) {
                this.props.history.push(`${AppNavPaths.Shipping}/${wcid}/${shpid}`);
            } else {
                this.props.history.push(`${AppNavPaths.WorkCenterWorkFlow}/${wcid}/${fillForm.salesOrderTrackingId}`);
            }
        }
    }

    onShowSalesOrderTrackingClicked = () => {
        this.salesOrderTrackingSlideFormRef.current.open();
    }

    handleOnRemoveAnswerImage = async (idx, sq) => {
        let { fillForm } = { ...this.state };

        let q = fillForm.sectionQuestions.find(q => q.sectionQuestion.id == sq.sectionQuestion.id);

        let img = q.answer.images[idx];

        if (img.documentId == 0) {
            q.answer.images.splice(idx, 1);
        } else {

            this.setState({
                saving: true
            });

            try {
                let response = await util.fetch.delete(ApiRoutes.workCenter.workflowDeleteAnswerDocument(img.documentId), null, util.fetch.format.none);
                if (response.redirected) {
                    window.location.href = response.url;
                } else if (!!response.ok) {
                    var serviceResponse = await response.json();
                    if (serviceResponse.result === 'SUCCESS') {
                        this.notifySuccess();
                        q.answer.images.splice(idx, 1);
                    } else {
                        this.notifyError(serviceResponse.message);
                    }
                } else {

                    if (response.status === 400) {
                        let serviceResponse = await response.json();
                        this.displaySaveErrors(serviceResponse);
                    } else {
                        let serviceResponse = {
                            title: 'Server Error',
                            errors: {
                                Exception: [
                                    await response.error()
                                ]
                            }
                        }
                        this.displaySaveErrors(serviceResponse);
                    }
                }
            } catch (error) {
                this.notifyError(error.toString());
            } finally {
                this.clearSaving();
            }
        }

        this.setState({
            fillForm: fillForm
        });
    }

    handleCancelCamera = async () => {
        let { stream, video } = { ...this.webCamRef.current };

        let currentTracks = stream?.getTracks();

        if (currentTracks) {
            currentTracks.forEach(track => {
                track.stop();
                stream.removeTrack(track);
            });
        }

        if (video) {
            video.pause();
            video.src = "";
            video.srcObject = null;
        }

        this.setState({ getPicutre: false, activeImageQuestion: null });
    }

    switchWhichCamera = async () => {
        let { deviceIds, videoConstraints, currentDeviceId } = { ...this.state };

        let { stream, video } = { ...this.webCamRef.current };

        let currentTracks = stream?.getTracks();

        if (currentTracks) {
            currentTracks.forEach(track => {
                track.stop();
                stream.removeTrack(track);
            });
        }

        if (currentDeviceId == 0) {
            videoConstraints.deviceId = { exact: deviceIds[1] };
            currentDeviceId = 1;
            this.setState({
                currentDeviceId: 1
            });
        } else {
            videoConstraints.deviceId = { exact: deviceIds[0] };
            currentDeviceId = 0;
            this.setState({
                currentDeviceId: 0
            });
        }

        if (video) {
            video.pause();
            video.src = "";
            video.srcObject = null;

            navigator.mediaDevices.getUserMedia({
                video: videoConstraints,
                audio: false
            }).then((newStream) => {
                video.srcObject = newStream;
                video.play();
            });
        }
    }

    handleOnAnswerImage = () => {
        let { fillForm, activeImageQuestion } = { ...this.state };

        let q = fillForm.sectionQuestions.find(sq => sq.sectionQuestion.id == activeImageQuestion.sectionQuestion.id);

        q.answer.images.push(
            {
                documentId: 0,
                image: this.webCamRef.current.getScreenshot()
            }
        );

        this.setState({
            dirty: true,
            fillForm: fillForm,
            activeImageQuestion: null,
            getPicutre: false
        });
    }

    handleMultiSelectChange = (selected, qm) => {
        let { fillForm } = { ...this.state };

        let q = fillForm.sectionQuestions.find(sq => sq.sectionQuestion.id == qm.sectionQuestion.id);

        q.implementedQuestion.answerListPlaceholder = selected;

        if (!!selected) {
            q.answer.answerText = q.implementedQuestion.answerListPlaceholder.map((c) => c.choiceText).sort((a, b) => a < b ? -1 : 1).join(",");
        }

        let isValid = this.checkAnswer(q, q.answer.answerText);

        if (isValid) {
            q.answer.isIssue = false;
            q.answer.notes = "";
        } else {
            q.answer.isIssue = true;
        }

        this.setState({
            dirty: true,
            fillForm: fillForm
        });

    }

    handleChangeOptionGroupValue = (newChoice, qm) => {
        let { fillForm } = { ...this.state };

        let q = fillForm.sectionQuestions.find(sq => sq.sectionQuestion.id == qm.sectionQuestion.id);

        //an existing value
        if ((q.answer.answerText ?? "").length) {
            if (q.answer.answerText.indexOf(newChoice) > -1) {
                //value is already in the answer
                let allvals = q.answer.answerText.split(",");
                allvals.splice(allvals.indexOf(newChoice), 1);
                q.answer.answerText = allvals.sort((a, b) => a < b ? -1 : 1).join(",");
            } else {
                let allvals = q.answer.answerText.split(",");
                allvals.push(newChoice.toString());
                q.answer.answerText = allvals.sort((a, b) => a < b ? -1 : 1).join(",");
            }
        } else {
            q.answer.answerText = newChoice.toString();
        }

        let isValid = this.checkAnswer(q, q.answer.answerText);

        if (isValid) {
            q.answer.isIssue = false;
            q.answer.notes = "";
        } else {
            q.answer.isIssue = true;
        }

        this.setState({
            dirty: true,
            fillForm: fillForm
        });

    }

    handleSetNewAnswerValue = (newValue, qm) => {
        let { fillForm } = { ...this.state };

        let q = fillForm.sectionQuestions.find(sq => sq.sectionQuestion.id == qm.sectionQuestion.id);

        q.answer.answerText = newValue.choiceText;
        let isValid = this.checkAnswer(q, q.answer.answerText);

        if (isValid) {
            q.answer.isIssue = false;
            q.answer.notes = "";
        } else {
            q.answer.isIssue = true;
        }

        this.setState({
            dirty: true,
            fillForm: fillForm
        });
    }

    handleAnswerBlur = (e, qm) => {
        let { fillForm } = { ...this.state };

        //e.preventDefault();
        e.stopPropagation();

        let q = fillForm.sectionQuestions.find(sq => sq.sectionQuestion.id == qm.sectionQuestion.id);

        q.answer.answerText = e.target.value;
        let isValid = this.checkAnswer(q, q.answer.answerText);

        if (isValid) {
            q.answer.isIssue = false;
            q.answer.notes = "";
        } else {
            q.answer.isIssue = true;
        }

        this.setState({
            dirty: true,
            fillForm: fillForm
        });
    }

    handleAnswerEnterKey = (e, qm) => {
        if (e.key === 'Enter') {
            let { fillForm } = { ...this.state };

            let q = fillForm.sectionQuestions.find(sq => sq.sectionQuestion.id == qm.sectionQuestion.id);

            q.answer.answerText = e.target.value;
            let isValid = this.checkAnswer(q, q.answer.answerText);

            if (isValid) {
                q.answer.isIssue = false;
                q.answer.notes = "";
            } else {
                q.answer.isIssue = true;
            }

            this.setState({
                dirty: true,
                fillForm: fillForm
            });
        }
    }

    transformValue = (value, type) => {
        if (!!value && !!type) {
            switch (type) {
                case 'System.String': return value.toString()
                case 'System.Int64': return Number(value)
                case 'System.Decimal': return parseFloat(value)
                case 'System.Boolean': return !!value
                case 'System.DateTime': return Date.parse(value)
                default: return null
            }
        }
        return null;
    }

    checkAnswer = (question, answer) => {

        if (!!(question.implementedQuestion.question.expectedValues ?? []).length) {

            let allOrAnswers = [];
            let allXorAnswers = [];
            let andIsGoodAnswer = true;

            let tans = this.transformValue(answer, question.implementedQuestion.question.question.unitOfMeasurementDataType);

            if (tans == null) {
                //if we can't transform the value then the answer is bad
                return false;
            }

            //All And values must evaluate to true and we will process those first.
            //for example someone deifnes expected values foo/noteuqal/and, bar/noteuqal/and, foobar/equal/or
            //then in the input was either foo or bar alone it should fail validation but foobar should pass
            //validation. Or if somoene defines 10/greaterthan/and, 20/lessthan/and, 22/equal/or then when someone
            //enters 22, the And portion should say false, but then when we evaluate the Or portion, it will flip
            //true. Exclusive or is a bit trickier because using similar expected values
            //10/greaterthan/and, 20/lessthan/and, 13/equal/xor then if someone entered 13 it should always fail where as
            //foo/equal/xor, bar/notequal/xor means only values not foo or bar are acceptable
            //Since exclusive or is the most aggresive it gets processed last. So if you have and values, those must all
            //evaluate true, then any or values must have at least one value evaluate true, and finally if there is an 
            //exclusive or value, then you must make sure that only one true value among all values is found

            let andValues = question.implementedQuestion.question.expectedValues.filter(ev =>
                ev.questionExpectedValueRelationshipId == questionExpectedValueRelationship.And
            );

            if (!!andValues.length) {
                andValues.forEach((val) => {

                    let tval = this.transformValue(val.expectedValue, question.implementedQuestion.question.question.unitOfMeasurementDataType);

                    //If the answer has not already been flipped false, then check the value. 
                    //One false value forces the whole 'and' set of values false. 
                    if (!!andIsGoodAnswer) {
                        switch (val.questionExpectedValueTypeId) {
                            case questionEpectedValueType.Equal: andIsGoodAnswer = tval == tans; break;
                            case questionEpectedValueType.LessThan: andIsGoodAnswer = tans <= tval; break;
                            case questionEpectedValueType.GreaterThan: andIsGoodAnswer = tans >= tval; break;
                            case questionEpectedValueType.NotEqual: andIsGoodAnswer = tans != tval; break;
                            case questionEpectedValueType.StartsWith: andIsGoodAnswer = tans.toString().startsWith(tval); break;
                            case questionEpectedValueType.Contains: andIsGoodAnswer = tans.toString().includes(tval); break;
                            case questionEpectedValueType.EndsWith: andIsGoodAnswer = tans.toString().endsWith(tval); break;
                            default: andIsGoodAnswer = false; break; //if we don't have a valid vlaue type, then it can't be validated
                        }
                    }
                });
            }

            let orValues = question.implementedQuestion.question.expectedValues.filter(ev =>
                ev.questionExpectedValueRelationshipId == questionExpectedValueRelationship.Or
            );

            if (!!orValues.length) {
                orValues.forEach((val) => {
                    let curvalue = true;
                    let tval = this.transformValue(val.expectedValue, question.implementedQuestion.question.question.unitOfMeasurementDataType);

                    switch (val.questionExpectedValueTypeId) {
                        case questionEpectedValueType.Equal: curvalue = tval == tans; break;
                        case questionEpectedValueType.LessThan: curvalue = tans <= tval; break;
                        case questionEpectedValueType.GreaterThan: curvalue = tans >= tval; break;
                        case questionEpectedValueType.NotEqual: curvalue = tans != tval; break;
                        case questionEpectedValueType.StartsWith: curvalue = tans.toString().startsWith(tval); break;
                        case questionEpectedValueType.Contains: curvalue = tans.toString().includes(tval); break;
                        case questionEpectedValueType.EndsWith: curvalue = tans.toString().endsWith(tval); break;
                        default: curvalue = false; break; //if we don't have a valid vlaue type, then it can't be validated
                    }

                    allOrAnswers.push(curvalue);
                });
            }

            let xorValues = question.implementedQuestion.question.expectedValues.filter(ev =>
                ev.questionExpectedValueRelationshipId == questionExpectedValueRelationship.ExclusiveOr
            );

            if (!!xorValues.length) {
                xorValues.forEach((val) => {
                    let curvalue = true;
                    let tval = this.transformValue(val.expectedValue, question.implementedQuestion.question.question.unitOfMeasurementDataType);

                    switch (val.questionExpectedValueTypeId) {
                        case questionEpectedValueType.Equal: curvalue = tval == tans; break;
                        case questionEpectedValueType.LessThan: curvalue = tans <= tval; break;
                        case questionEpectedValueType.GreaterThan: curvalue = tans >= tval; break;
                        case questionEpectedValueType.NotEqual: curvalue = tans != tval; break;
                        case questionEpectedValueType.StartsWith: curvalue = tans.toString().startsWith(tval); break;
                        case questionEpectedValueType.Contains: curvalue = tans.toString().includes(tval); break;
                        case questionEpectedValueType.EndsWith: curvalue = tans.toString().endsWith(tval); break;
                        default: curvalue = false; break; //if we don't have a valid vlaue type, then it can't be validated
                    }

                    allXorAnswers.push(curvalue);
                });
            }

            if (!!orValues.length || !!xorValues.length || !!andValues.length) {

                //If we have all three types and there is an xor value, only one of the answers
                //among all the answers can come back true. 
                if (!!orValues.length && !!xorValues.length && !!andValues.length) {
                    let orAnswer = allOrAnswers.some(v => v); //If any one value is true then the or clause is satisfied
                    let overallAnswer = andIsGoodAnswer || orAnswer;
                    let xorAnswer = true;

                    //If all the xor values are the same, then it is also false
                    if (allXorAnswers.every(v => v) || allXorAnswers.every(v => !v)) {
                        xorAnswer = false;
                    }

                    if (allXorAnswers.filter(x => x).length > 1) {
                        xorAnswer = false;
                    }

                    //finally we need to make sure there is only one true value 
                    let aggregate = [];
                    aggregate.push(overallAnswer);
                    aggregate.push(xorAnswer);

                    if (aggregate.filter(x => x).length > 1) {
                        return false; //Because of xor, we need to be sure that only one value is true
                    }
                    return true;

                } else {

                    if (!!orValues.length && !!xorValues.length) {
                        let orAnswer = allOrAnswers.some(v => v)
                        let xorAnswer = true;

                        //If all the xor values are the same, then it is also false
                        if (allXorAnswers.every(v => v) || allXorAnswers.every(v => !v)) {
                            xorAnswer = false;
                        }

                        if (allXorAnswers.filter(x => x).length > 1) {
                            xorAnswer = false;
                        }

                        //finally we need to make sure there is only one true value 
                        let aggregate = [];
                        aggregate.push(orAnswer);
                        aggregate.push(xorAnswer);

                        if (aggregate.filter(x => x).length > 1) {
                            return false; //Because of xor, we need to be sure that only one value is true
                        }
                        return true;
                    }

                    if (!!xorValues.length && !!andValues.length) {
                        let xorAnswer = true;

                        //If all the xor values are the same, then it is also false
                        if (allXorAnswers.every(v => v) || allXorAnswers.every(v => !v)) {
                            xorAnswer = false;
                        }

                        if (allXorAnswers.filter(x => x).length > 1) {
                            xorAnswer = false;
                        }

                        //finally we need to make sure there is only one true value 
                        let aggregate = [];
                        aggregate.push(andIsGoodAnswer);
                        aggregate.push(xorAnswer);

                        if (aggregate.filter(x => x).length > 1) {
                            return false; //Because of xor, we need to be sure that only one value is true
                        }
                        return true;
                    }

                    if (!!orValues.length && !!andValues.length) {
                        let orAnswer = allOrAnswers.some(v => v); //If any one value is true then the or clause is satisfied
                        return andIsGoodAnswer || orAnswer;
                    }

                    if (!!andValues.length) {
                        return andIsGoodAnswer;
                    }

                    if (!!orValues.length) {
                        return allOrAnswers.some(v => v);
                    }

                    if (!!xorValues.length) {
                        let xorAnswer = true;

                        //If all the xor values are the same, then it is also false
                        if (xorValues.length > 1) {
                            if (allXorAnswers.every(v => v) || allXorAnswers.every(v => !v)) {
                                xorAnswer = false;
                            }

                            if (allXorAnswers.filter(x => x).length > 1) {
                                xorAnswer = false;
                            }
                        } else {
                            xorAnswer = allXorAnswers[0]; //Should be only one answer and it is T/F so essentially just return that
                        }

                        return xorAnswer;
                    }
                }
            } else {
                return andIsGoodAnswer;
            }
        }
        //No expected values, then any answer is good
        return true;
    }

    onSubmitAnswers = async () => {
        this.setState({ saving: true });

        let { fillForm } = { ...this.state };
        let { wcid } = { ...this.props.match.params };

        let answers = fillForm.sectionQuestions.map(sq => sq.answer);

        if (answers.some(a => !!(a.images.length) && !!!a.answerText)) {
            this.notifyError("Images must have an accompanying answer");
            this.clearSaving();
            return false;
        }

        let answersToSave = answers.filter(a => !!a.answerText || a.id != 0);

        if (answersToSave.some(a => a.isIssue && !!!a.notes)) {
            this.notifyError("Please ensure that all issues have accompanying notes");
            this.clearSaving();
            return false;
        }

        let dto = {
            workCenterId: wcid,
            answers: answersToSave
        }

        try {
            let response = await util.fetch.post(ApiRoutes.workCenter.workflowAnswerSurvey(), dto, util.fetch.format.none);
            if (response.redirected) {
                window.location.href = response.url;
            } else if (!!response.ok) {
                var serviceResponse = await response.json();
                if (serviceResponse.result === 'SUCCESS') {
                    this.notifySuccess();
                    this.setState({
                        dirty: false
                    });
                    await this.populateState();
                } else {
                    this.notifyError(serviceResponse.message);
                }
            } else {

                if (response.status === 400) {
                    let serviceResponse = await response.json();
                    this.displaySaveErrors(serviceResponse);
                } else {
                    let serviceResponse = {
                        title: 'Server Error',
                        errors: {
                            Exception: [
                                await response.error()
                            ]
                        }
                    }
                    this.displaySaveErrors(serviceResponse);
                }
            }
        } catch (error) {
            this.notifyError(error.toString());
        } finally {
            this.clearSaving();
        }
    }

    onSubmitForm = async () => {
        this.setState({ saving: true });

        let { fillForm } = { ...this.state };
        let { wcid } = { ...this.props.match.params };

        let questionsNeedAnswered = fillForm.sectionQuestions.filter(q => q.sectionQuestion.answerRequired).map(q => q);

        if (questionsNeedAnswered.some(q =>
            !!!q.answer.answerText
        )) {
            this.notifyError("Please ensure that all required questions have an answer");
            this.clearSaving();
            return false;
        }

        //let answers = fillForm.sectionQuestions.map(sq => sq.answer);
        //let answersToSave = answers.filter(a => !!a.answerText);
        let answersToSave = fillForm.sectionQuestions.map(sq => sq.answer);

        if (answersToSave.some(a => a.isIssue && !!!a.notes)) {
            this.notifyError("Please ensure that all issues have accompanying notes");
            this.clearSaving();
            return false;
        }
        
        let dto = {
            workCenterId: wcid,
            salesOrderTrackingSurveyId: fillForm.id,
            answers: answersToSave
        }

        try {
            let response = await util.fetch.post(ApiRoutes.workCenter.workflowSubmitSurvey(), dto, util.fetch.format.none);
            if (response.redirected) {
                window.location.href = response.url;
            } else if (!!response.ok) {
                var serviceResponse = await response.json();
                if (serviceResponse.result === 'SUCCESS') {
                    fillForm.salesOrderTrackingSurveyCompletionStatusId = 2;
                    fillForm.salesOrderTrackingSurveyCompletionStatus = "Submitted";
                    this.notifySuccess();

                    this.onCloseClicked();

                } else {
                    this.notifyError(serviceResponse.message);
                }
            } else {

                if (response.status === 400) {
                    let serviceResponse = await response.json();
                    this.displaySaveErrors(serviceResponse);
                } else {
                    let serviceResponse = {
                        title: 'Server Error',
                        errors: {
                            Exception: [
                                await response.error()
                            ]
                        }
                    }
                    this.displaySaveErrors(serviceResponse);
                }
            }
        } catch (error) {
            this.notifyError(error.toString());
        } finally {
            this.clearSaving();
        }
    }

    onChangeStatus = async () => {
        let { fillForm, chosenSurveyStatus } = { ...this.state };

        let dto = {
            WorkCenterSurveyId: fillForm.id,
            SalesOrderTrackingSurveyCompletionStatusId: chosenSurveyStatus
        }

        try {
            let response = await util.fetch.post(ApiRoutes.workCenter.workflowChangeFormStatus(), dto, util.fetch.format.none);
            if (response.redirected) {
                window.location.href = response.url;
            } else if (!!response.ok) {
                var serviceResponse = await response.json();
                if (serviceResponse.result === 'SUCCESS') {

                    this.notifySuccess();

                    await this.populateState();
                } else {
                    this.notifyError(serviceResponse.message);
                }
            } else {

                if (response.status === 400) {
                    let serviceResponse = await response.json();
                    this.displaySaveErrors(serviceResponse);
                } else {
                    let serviceResponse = {
                        title: 'Server Error',
                        errors: {
                            Exception: [
                                await response.error()
                            ]
                        }
                    }
                    this.displaySaveErrors(serviceResponse);
                }
            }
        } catch (error) {
            this.notifyError(error.toString());
        } finally {
            this.clearSaving();
        }
    }

    handleAnswerNotesUpdatedBlur = (e, qm) => {
        let { fillForm } = { ...this.state };

        let q = fillForm.sectionQuestions.find(sq => sq.sectionQuestion.id == qm.sectionQuestion.id);

        q.answer.notes = e.target.value;

        this.setState({
            dirty: true,
            fillForm: fillForm
        });
    }

    onExportSalesOrderTrackingSurveyClicked = async () => {
        let { wcid, sotsid } = { ...this.props.match.params };
        let { fillForm } = { ...this.state };

        this.setState({
            loading: true
        });

        let dto = {
            WorkCenterId: wcid,
            WorkCenterSurveyId: 0,
            SalesOrderTrackingId: sotsid
        }

        var [exportResponse] = await Promise.all([
            !!(fillForm.survey.surveyExportFormatId) ?
                util.fetch.post(ApiRoutes.workCenter.exportsalesordersurvey(), dto, util.fetch.format.file)
                : util.fetch.post(ApiRoutes.workCenter.exportsalesordersurveygeneral(), dto, util.fetch.format.file)
        ]);

        if (!!exportResponse?.error ?? false) {
            //handle erroneous links entered by the user
            let serviceresponse = {
                title: 'server error',
                errors: {
                    exception: [
                        exportResponse.error
                    ]
                }
            }
            this.displaySaveErrors(serviceresponse);
            return false;
        } else {
            const a = document.createElement("a");
            a.href = exportResponse.url;
            a.target = "_blank";
            a.download = exportResponse.fileName;
            document.body.appendChild(a);
            a.click();
            a.remove();
        }

        this.setState({
            loading: false
        });
    }

    render() {
        const {
            loading,
            fillForm,
            dirty,
            noCamera,
            videoConstraints,
            deviceIds,
            formStatuses
        } = { ...this.state };

        let {
            errorResponse,
            getPicutre,
            lbIsOpen,
            lbImgIndex,
            lbImages,
            salesOrderTrackingId,
            WorkCenterId,
            chosenSurveyStatus
        } = { ...this.state };

        (fillForm?.sections ?? []).sort((s1, s2) => s1.order < s2.order ? -1 : 1);

        let isSubmitted = (fillForm.salesOrderTrackingSurveyCompletionStatusId == 2);

        if (!!loading) {
            return (<LinearProgress variant="indeterminate" color="secondary" />);
        } else {

            return (
                <Fragment>
                    <AppPageForm
                        formId={"salesOrderTrackingSurveyFillForm"}
                        formHeadingIcon={faTasks}
                        formHeading={`Form: ${fillForm.survey.name}`}
                        formName={"salesOrderTrackingSurveyFillForm"}
                        formRef={this.formRef}
                        setIsValidated={(value) => { this.setState({ formValidated: value }) }}
                        isValidated={this.state.formValidated}
                        saving={this.state.saving}
                        errors={this.state.errors}
                        loading={this.state.loading}
                    >
                        <Container>
                            <Row>
                                <Col xs={10} sm={10} md={8} lg={8} xl={8} xxl={8} >
                                    <span>{fillForm.survey.name}</span>                                    
                                </Col>
                                <Col xs={2} sm={2} md={4} lg={4} xl={4} xxl={4} >
                                    <Can do="all" on="administrator">
                                        <FormGroup style={{ margin: "0" }} >
                                            <Label style={{ color: "#777", fontWeight: "600", fontSize: ".9em" }}
                                                check htmlFor="fillForm.readOnly" >
                                                {"Read Only"}
                                            </Label>
                                            <Input
                                                id="fillForm.readOnly"
                                                name="fillForm.readOnly"
                                                autoComplete="off"

                                                onChange={this.onChange}
                                                type="checkbox"
                                                checked={fillForm.readOnly}
                                            />
                                        </FormGroup>
                                    </Can>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} sm={12} md={4} lg={4} xl={4} xxl={4} >
                                    <span>{fillForm.survey.version.toFixed(1)}</span>
                                </Col>
                                <Col xs={false} sm={false} md={8} lg={8} xl={8} xxl={8}></Col>
                            </Row>
                        </Container>
                        {!!(fillForm.sections ?? []).length && fillForm.sections.map((s, ind) =>
                            <Container className={"form-question-list"} key={s.id.toString() + ind.toString() + "section"}>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>{`Section: ${s.sectionName}`}</Col>
                                </Row>
                                {!!s.instructions &&
                                    <Row>
                                        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}
                                            style={{ fontSize: "smaller", fontStyle: "italic" }}
                                        >
                                            {s.instructions}
                                        </Col>
                                    </Row>
                                }
                                {!!fillForm.sectionQuestions.some(sq => sq.sectionQuestion.surveySectionId == s.id)
                                    && fillForm.sectionQuestions.filter((sq) =>
                                        sq.sectionQuestion.surveySectionId == s.id
                                        && !!!(sq.implementedQuestion.question.question.isHidden)
                                    ).sort((sq1, sq2) => sq1.sectionQuestion.order < sq2.sectionQuestion.order ? -1 : 1).map((sq) =>
                                        <Row key={sq.sectionQuestion.id.toString() + 'sectionQuestion'} style={{ marginTop: "1em", background: sq.sectionQuestion.order % 2 == 0 ? "inherit" : "#FAFAFA" }} >
                                            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                <Container>
                                                    <Row style={sq.answer.isIssue ? {
                                                        borderTop: "2px solid red",
                                                        borderLeft: "2px solid red",
                                                        borderRight: "2px solid red",
                                                        paddingTop: "1em"
                                                    } : { paddingTop: "1em" }} >
                                                        <Col xs={12} sm={12} md={6} lg={8} xl={8} xxl={10}>
                                                            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                                <label className={sq.sectionQuestion.answerRequired ? "required" : ""} >
                                                                    {sq.implementedQuestion.question.question.title}
                                                                </label>
                                                            </Col>
                                                            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}
                                                                style={{ fontSize: "smaller", fontStyle: "italic" }}
                                                            >
                                                                {sq.implementedQuestion.question.question.subText}
                                                            </Col>
                                                        </Col>
                                                        <Col xs={12} sm={12} md={6} lg={4} xl={4} xxl={2}>
                                                            {(sq.implementedQuestion.question.question.inputTypeId == questionInputTypes.Derived) &&
                                                                <FormGroup>
                                                                    <Input
                                                                        disabled={true}
                                                                        type="text"
                                                                        value={sq.answer.answerText ?? ""}
                                                                        onChange={(e) => { }}
                                                                        onKeyDown={(e) => { }}
                                                                        onBlur={(e) => { }}
                                                                    />
                                                                </FormGroup>
                                                            }
                                                            {(sq.implementedQuestion.question.question.inputTypeId == questionInputTypes.Numeric) &&
                                                                <FormGroup>
                                                                    <InputGroup>
                                                                        <Input
                                                                            disabled={(!!sq.answer.isIssue && !!sq.answer.id) || !!(isSubmitted) || fillForm.readOnly}
                                                                            type="text"
                                                                            value={(sq.answer.answerText ?? '') && !isNaN(sq.answer.answerText) ? Number(sq.answer.answerText) : 0}
                                                                            onChange={(e) => { this.handleAnswerBlur(e, sq) }}
                                                                            onKeyDown={(e) => { this.handleAnswerEnterKey(e, sq) }}
                                                                            onWheel={(e) => { this.handleAnswerBlur(e, sq) }}
                                                                        />
                                                                    </InputGroup>
                                                                    <InputGroupText>{sq.implementedQuestion.question.question.unitOfMeasurementText}</InputGroupText>
                                                                </FormGroup>
                                                            }
                                                            {(sq.implementedQuestion.question.question.inputTypeId == questionInputTypes.SingleLineText) &&
                                                                <FormGroup>
                                                                    <Input
                                                                        disabled={(!!sq.answer.isIssue && !!sq.answer.id) || !!(isSubmitted) || fillForm.readOnly}
                                                                        type="text"
                                                                        value={sq.answer.answerText ?? ""}
                                                                        onChange={(e) => { this.handleAnswerBlur(e, sq) }}
                                                                        onKeyDown={(e) => { this.handleAnswerEnterKey(e, sq) }}
                                                                        onBlur={(e) => { this.handleAnswerBlur(e, sq) }}
                                                                    />
                                                                </FormGroup>
                                                            }
                                                            {(sq.implementedQuestion.question.question.inputTypeId == questionInputTypes.Text) &&
                                                                <FormGroup>
                                                                    <textarea
                                                                        disabled={(!!sq.answer.isIssue && !!sq.answer.id) || !!(isSubmitted) || fillForm.readOnly}
                                                                        value={sq.answer.answerText ?? ""}
                                                                        onChange={(e) => { this.handleAnswerBlur(e, sq) }}
                                                                    />
                                                                </FormGroup>
                                                            }
                                                            {(sq.implementedQuestion.question.question.inputTypeId == questionInputTypes.DateTime) &&
                                                                <FormGroup>
                                                                    <Input
                                                                        disabled={(!!sq.answer.isIssue && !!sq.answer.id) || !!(isSubmitted) || fillForm.readOnly}
                                                                        type="date"
                                                                        value={sq.answer.answerText ?? ""}
                                                                        onChange={(e) => { this.handleAnswerBlur(e, sq) }}
                                                                        onKeyDown={(e) => { this.handleAnswerEnterKey(e, sq) }}
                                                                    />
                                                                </FormGroup>
                                                            }
                                                            {(sq.implementedQuestion.question.question.inputTypeId == questionInputTypes.OptionGroup) &&
                                                                !!(sq.implementedQuestion.questionOptionGroup) &&
                                                                <>
                                                                    {!!sq.implementedQuestion.questionOptionGroup.group.isExclusiveChoice &&
                                                                        <FormGroup>
                                                                            {sq.implementedQuestion.questionOptionGroup.options.map((op) =>
                                                                                <FormGroup key={op.id + op.choiceText + sq.id + "optionGroup"} check>
                                                                                    <Input
                                                                                        disabled={(!!sq.answer.isIssue && !!sq.answer.id) || !!(isSubmitted) || fillForm.readOnly}
                                                                                        type="radio"
                                                                                        value={op.choiceText}
                                                                                        checked={(sq.answer.answerText ?? "") == op.choiceText}
                                                                                        className="form-control"
                                                                                        onChange={() => { this.handleSetNewAnswerValue(op, sq) }}
                                                                                        style={{ width: "3rem", right: "0" }}
                                                                                    />
                                                                                    {' '}
                                                                                    <Label style={{ color: "#777", fontWeight: "600", fontSize: ".9rem", lineHeight: "45px" }} check>
                                                                                        {op.choiceText}
                                                                                    </Label>
                                                                                </FormGroup>
                                                                            )
                                                                            }
                                                                        </FormGroup>
                                                                    }
                                                                    {!!!sq.implementedQuestion.questionOptionGroup.group.isExclusiveChoice &&
                                                                        <FormGroup>
                                                                            {sq.implementedQuestion.questionOptionGroup.options.map((op) =>
                                                                                <FormGroup key={op.id + op.choiceText + sq.id + "optionGroup"} check>
                                                                                    <Input
                                                                                        disabled={(!!sq.answer.isIssue && !!sq.answer.id) || !!(isSubmitted) || fillForm.readOnly}
                                                                                        type="checkbox"
                                                                                        checked={(sq.answer.answerText ?? "").includes(op.choiceText)}
                                                                                        className="form-control"
                                                                                        onChange={() => { this.handleChangeOptionGroupValue(op.choiceText, sq) }}
                                                                                    />
                                                                                    {' '}
                                                                                    <Label style={{ color: "#777", fontWeight: "600", fontSize: ".9em", lineHeight: "45px" }} check>
                                                                                        {op.choiceText}
                                                                                    </Label>
                                                                                </FormGroup>
                                                                            )
                                                                            }
                                                                        </FormGroup>
                                                                    }
                                                                </>
                                                            }
                                                            {(sq.implementedQuestion.question.question.inputTypeId == questionInputTypes.Select) &&
                                                                !!(sq.implementedQuestion.questionOptionGroup) &&
                                                                <>
                                                                    {sq.implementedQuestion.questionOptionGroup.group.isExclusiveChoice &&
                                                                        <FormGroup>
                                                                            <ValidatedSelect
                                                                                isDisabled={(!!sq.answer.isIssue && !!sq.answer.id) || !!(isSubmitted) || fillForm.readOnly}
                                                                                options={sq.implementedQuestion.questionOptionGroup.options}
                                                                                value={(sq.implementedQuestion.questionOptionGroup.options ?? []).find(s => s.choiceText == sq.answer.answerText) ?? ''}
                                                                                getOptionLabel={option => option.choiceText}
                                                                                getOptionValue={option => option.id}
                                                                                validationMessage=""
                                                                                onChange={(selection) => { this.handleSetNewAnswerValue(selection, sq) }}
                                                                            />
                                                                        </FormGroup>
                                                                    }
                                                                    {!sq.implementedQuestion.questionOptionGroup.group.isExclusiveChoice &&
                                                                        <FormGroup >
                                                                            <ValidatedSelect
                                                                                isDisabled={(!!sq.answer.isIssue && !!sq.answer.id) || !!(isSubmitted) || fillForm.readOnly}
                                                                                isMulti
                                                                                options={sq.implementedQuestion.questionOptionGroup.options}
                                                                                value={sq.implementedQuestion.answerListPlaceholder ?? ''}
                                                                                getOptionLabel={option => option.choiceText}
                                                                                getOptionValue={option => option.id}
                                                                                validationMessage=""
                                                                                onChange={(selection) => { this.handleMultiSelectChange(selection, sq) }}
                                                                            />
                                                                        </FormGroup>
                                                                    }
                                                                </>
                                                            }
                                                        </Col>
                                                    </Row>
                                                    {(!!(sq.answer?.isIssue) || !!(sq.answer?.notes)) &&
                                                        <Row style={sq.answer.isIssue ? {
                                                            borderLeft: "2px solid red",
                                                            borderRight: "2px solid red",
                                                            paddingTop: "1em"
                                                        } : { paddingTop: "1em" }}>
                                                            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                                <label className={sq.sectionQuestion.answerRequired ? "required" : ""}>
                                                                    {"Please describe the issue"}
                                                                </label>
                                                            </Col>
                                                            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                                <FormGroup>
                                                                    <textarea
                                                                        disabled={!!!(sq.answer?.isIssue)}
                                                                        className={"form-control"}
                                                                        value={sq.answer.notes ?? ""}
                                                                        onChange={(e) => { this.handleAnswerNotesUpdatedBlur(e, sq) }}
                                                                    ></textarea>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    }
                                                    {!!((sq.implementedQuestion.question.question.allowPicture || sq.answer?.isIssue || !!((sq.answer?.images ?? []).length)) && !!((sq.answer?.images ?? []).length)) &&
                                                        <Row
                                                            style={sq.answer.isIssue ? {
                                                                borderLeft: "2px solid red",
                                                                borderRight: "2px solid red",
                                                                paddingTop: "1em",
                                                                flexWrap: "nowrap",
                                                                overflowX: "auto"
                                                            } : { paddingTop: "1em", flexWrap: "nowrap", overflowX: "auto" }}
                                                        >
                                                            {sq.answer?.images.map((i, idx) =>
                                                                <Col key={uuid()} xs={12} sm={6} md={6} lg={3} xl={3} xxl={3} style={{ textAlign: 'center' }} >
                                                                    <div className={"answer-image-thumbnail-wrapper"} >
                                                                        <FontAwesomeIcon
                                                                            className={"remove-img-icon"}
                                                                            icon={faTimes}
                                                                            onClick={() => {
                                                                                this.handleOnRemoveAnswerImage(idx, sq);
                                                                            }}
                                                                        />
                                                                        <img className={"answer-image-thumbnail"}
                                                                            src={i.image} alt={""}
                                                                            onClick={() => {
                                                                                let imgs = sq.answer.images.map((i) => { return { src: i.image } });

                                                                                this.setState({
                                                                                    lbIsOpen: true,
                                                                                    lbImgIndex: idx,
                                                                                    lbImages: imgs
                                                                                });
                                                                            }
                                                                            }
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            )}
                                                        </Row>
                                                    }
                                                    {!!(sq.implementedQuestion.question.question.allowPicture || sq.answer?.isIssue) &&
                                                        <Row
                                                            style={sq.answer.isIssue ? {
                                                                borderBottom: "2px solid red",
                                                                borderLeft: "2px solid red",
                                                                borderRight: "2px solid red",
                                                                paddingTop: "1em"
                                                            } : { paddingTop: "1em" }}
                                                        >
                                                            <Col xs={12} sm={12} md={2} lg={1} xl={1} xxl={1}>
                                                                <FontAwesomeIcon
                                                                    className="mr-2 text-dark pr-2 zoom"
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            getPicutre: true,
                                                                            activeImageQuestion: sq
                                                                        });
                                                                    }}
                                                                    icon={faCamera} />
                                                            </Col>
                                                        </Row>
                                                    }
                                                </Container>
                                            </Col>
                                        </Row>
                                    )
                                }
                            </Container>
                        )
                    }
                    <FlexCenterRow className="mb-3">
                        {(!!dirty && !fillForm.readOnly) &&
                            <Button
                                size="sm"
                                type="button"
                                color="primary"
                                name="formForm"
                                className="ml-2"
                                disabled={this.state.saving}
                                onClick={() => this.onSubmitAnswers()}
                            >
                                <FontAwesomeIcon
                                    className="mr-2"
                                    icon={faSave} />
                                {'Save Answers'}
                            </Button>
                        }
                        {(!!!(isSubmitted) && !fillForm.readOnly) &&
                            <Button
                                size="sm"
                                type="button"
                                color="primary"
                                name="formForm"
                                className="ml-2"
                                onClick={() => this.onSubmitForm()}
                                disabled={this.state.saving}
                            >
                                <FontAwesomeIcon
                                    className="mr-2"
                                    icon={faPaperPlane} />
                                {'Submit Form'}
                            </Button>
                        }
                        <Button
                            size="sm"
                            type="button"
                            color="secondary"
                            name="formFormClose"
                            onClick={this.onCloseClicked}
                            disabled={this.state.saving}
                            className="ml-2"
                        >
                            <FontAwesomeIcon
                                className="mr-2"
                                icon={faTimes} />
                            {"Close"}
                        </Button>                                
                        {!!(isSubmitted) &&
                            <Button
                                size="sm"
                                type="button"
                                color="secondary"
                                name="exportSalesOrderTrackingSurvey"
                                onClick={this.onExportSalesOrderTrackingSurveyClicked}
                                className="ml-2"
                                id="exportSalesOrderTrackingSurvey"
                            >
                                <FontAwesomeIcon
                                    className="mr-2"
                                    icon={faFilePdf} />
                                {"Export"}
                            </Button>
                        }
                        <Button
                            size="lg"
                            type="button"
                            color="secondary"
                            name="showSalesOrderTracking"
                            onClick={this.onShowSalesOrderTrackingClicked}
                            className="ml-2"
                            id="showSalesOrderTrackingButton"
                        >
                            <span>ORDER</span>
                            <FontAwesomeIcon className="mr-2" icon="fa-chart-line" />
                            <span>TRACKING</span>
                            <span>{"Show Order Tracking"}</span>
                        </Button>
                    </FlexCenterRow>
                        <FlexRow>
                            <Can do="all" on="administrator">
                                <FormGroup>
                                    <FormLabel htmlFor="surveyStatusOverride"
                                        text="Change Form Status to:"
                                    />
                                    <ValidatedSelect
                                        id="surveyStatusOverride"
                                        name="chosenSurveyStatus"
                                        options={formStatuses}
                                        value={(formStatuses ?? []).find(s => s.value == chosenSurveyStatus) ?? ''}
                                        onChange={(selection) => {
                                            chosenSurveyStatus = selection?.value;
                                            this.setState({ chosenSurveyStatus: chosenSurveyStatus });
                                        }}
                                    />
                                    <Button
                                        size="sm"
                                        type="button"
                                        color="primary"
                                        name="changeFormCompletionStatus"
                                        className="mt-2"
                                        onClick={() => this.onChangeStatus()}
                                    >
                                        <FontAwesomeIcon
                                            className="mr-2"
                                            icon={faPaperPlane} />
                                        {'Change Status'}
                                    </Button>
                                </FormGroup>
                            </Can>
                        </FlexRow>
                        <FlexRow>
                            <FormErrorResponseDisplay onClear={this.clearSaveErrors} response={errorResponse} />
                        </FlexRow>
                    </AppPageForm>
                    {
                        <>
                            {
                                <SalesOrderTrackingSlideForm
                                    id="salesOrderTrackingSlideForm"
                                    ref={this.salesOrderTrackingSlideFormRef}
                                    salesOrderTrackingId={salesOrderTrackingId}
                                    workCenterId={WorkCenterId}
                                    routingHistory={this.props.history}
                                />
                            }
                        </>
                    }
                    <div>
                        <Modal isOpen={getPicutre} size={"lg"} >
                            <ModalHeader>Take Picture</ModalHeader>
                            <ModalBody style={{ textAlign: "center" }} >
                                {noCamera && <div><p>Camera is not present or permission was not granted.</p></div>}
                                {!noCamera &&
                                    <Webcam
                                        audio={false}
                                        height={500}
                                        ref={this.webCamRef}
                                        screenshotFormat="image/jpeg"
                                        width={"100%"}
                                        videoConstraints={videoConstraints}
                                    />
                                }
                            </ModalBody>
                            <ModalFooter>
                                {(deviceIds.length > 1) &&
                                    <Button
                                        size="sm"
                                        type="button"
                                        color="secondary"
                                        name="SwitchCamera"
                                        onClick={() => this.switchWhichCamera()}
                                    >
                                        <FontAwesomeIcon
                                            className="mr-2"
                                            icon={faRepeat} />
                                        {"Switch Camera"}
                                    </Button>
                                }
                                {!noCamera &&
                                    <Button
                                        size="sm"
                                        type="button"
                                        color="primary"
                                        name="takeCapture"
                                        onClick={() => this.handleOnAnswerImage()}
                                    >
                                        <FontAwesomeIcon
                                            className="mr-2"
                                            icon={faCamera} />
                                        {"Take Picture"}
                                    </Button>
                                }
                                <Button
                                    size="sm"
                                    type="button"
                                    color="secondary"
                                    name="cancelTakeCapture"
                                    onClick={() => this.handleCancelCamera()}
                                    className="ml-2">
                                    <FontAwesomeIcon
                                        className="mr-2"
                                        icon={faTimes} />
                                    {"Cancel"}
                                </Button>
                            </ModalFooter>
                        </Modal>
                    </div>
                    <div>
                        <Lightbox
                            open={lbIsOpen}
                            index={lbImgIndex}
                            close={() =>
                                this.setState({
                                    lbIsOpen: false,
                                    lbImgIndex: 0,
                                    lbImages: []
                                })
                            }
                            slides={lbImages}
                        />
                    </div>
                    <Prompt
                        when={!this.state.saving && !!dirty}
                        message='You have unsaved changes, are you sure you want to leave?'
                    />
                </Fragment>
            )
            
        }

    }

}
export default withRouter(FormFiller);