import React, { Fragment } from 'react';
import { Prompt, withRouter } from 'react-router-dom';

import { FlexRow, onFieldChange, toasty } from '../common/forms/FormElements';
import CommonContext, { ApiRoutes, AppNavPaths } from '../Common';
import { isEqual } from 'lodash-es';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTools, faSave, faTimes, faGears } from '@fortawesome/free-solid-svg-icons'
import {
    AppPageForm,
    FlexCenterRow,
    FormGroupColumn,
    FormLabel,
    GroupedRow
} from '../common/forms/FormElements';

import { LinearProgress } from '@material-ui/core';
import { util } from '../Util';

import {
    Button,
    FormGroup
} from 'reactstrap';

import { handleFormSaveError } from '../common/forms/ValidationError';
import authService from '../api-authorization/AuthorizeService';
import { getUserProfile } from '../common/UserProfile';
import FormErrorResponseDisplay from '../common/forms/FormErrorResponseDisplay';
import { MutableSettings } from './MutableSettings';
import ValidatedSelect from '../common/forms/ValidatedSelect';

class MutableSettingsForm extends React.Component {

    static contextType = CommonContext;

    constructor(props) {
        super(props);

        this.formRef = React.createRef();

        this.state = {
            formOpened: false,
            formValidated: false,
            loading: true,
            saving: false,
            statuses: [],
            workcenters: [],
            tailWorkcenters: [],
            chosenTailWorkcenter: null,
            perms: [],
            chosenWorkCenters: [],
            errorResponse: {}
        }
        this.onSubmit = this.onSubmit.bind(this);
        this.save = this.save.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleSaveError = this.handleSaveError.bind(this);
    }

    componentDidMount = () => {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount = async () => {
        await authService.unsubscribe(this._subscription);
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps && (this.props.match.params.id !== (prevProps.match.params ?? {}).id)) {
            this.populateState();
        }
    }

    async populateState() {
        const isAuthenticated = await authService.isAuthenticated();

        if (!!isAuthenticated) {
            const { userPermissions } = await getUserProfile();

            let workcenters = [];
            let tailWorkcenters = [];

            var [mutableSettingsResponse, workCenterResponse, workCenterTailsResponse] = await Promise.all([
                util.fetch.get(ApiRoutes.common.mutableSettings(), util.fetch.format.none)
                , util.fetch.get(ApiRoutes.typeAheads.getWorkCenters(), util.fetch.format.none)
                , util.fetch.get(ApiRoutes.typeAheads.getTailWorkCenters(), util.fetch.format.none)
            ]);

            if (!workCenterResponse.ok) {
                toasty.error('Failed to Load', 'Failed to load work centers. Please report this error.');
            } else {
                let raw_workcenters = await workCenterResponse.json();
                if (!!!raw_workcenters.length) {
                    toasty.error('Failed to Load', 'No Work centers found. Please report this error.');
                } else {
                    workcenters = raw_workcenters.map((rwc) => { return { value: parseInt(rwc.value), label: rwc.label }; });
                }
            }

            if (!workCenterTailsResponse.ok) {
                toasty.error('Failed to Load', 'Failed to load tail work centers. Please report this error.');
            } else {
                let raw_tail_workcenters = await workCenterTailsResponse.json();
                if (!!!raw_tail_workcenters.length) {
                    toasty.error('Failed to Load', 'No Tail Work centers found. Please report this error.');
                } else {
                    tailWorkcenters = raw_tail_workcenters.map(
                        (rwc) => { return { value: parseInt(rwc.value), label: rwc.label }; }
                    );
                }
            }

            var chosenWorkCenters = [];
            var tailWorkCenter = null;
            var mutableSettings = new MutableSettings();

            //Handle any issues fetching data
            if (!mutableSettingsResponse.ok) {
                //Handle erroneous links entered by the user
                if (mutableSettingsResponse.status === 404)
                    this.props.history.push(AppNavPaths.NotFound);
                else
                    this.props.history.push(AppNavPaths.ServerError);
                return false;
            } else {
                mutableSettings = await mutableSettingsResponse.json() ?? new MutableSettings();

                if (!!mutableSettings.changeOrderClearableWorkCenters) {
                    chosenWorkCenters = JSON.parse(mutableSettings.changeOrderClearableWorkCenters);                
                }
                if (!!mutableSettings.shippingActivityWorkCenterId) {
                    tailWorkCenter = parseInt(mutableSettings.shippingActivityWorkCenterId);
                }
            }

            this.setState({
                formValidated: false,
                saving: false,
                mutableSettings: mutableSettings,
                loading: false,
                originalData: mutableSettings,
                perms: userPermissions,
                workcenters: workcenters,
                tailWorkcenters: tailWorkcenters,
                chosenWorkCenters: chosenWorkCenters,
                chosenTailWorkcenter: tailWorkCenter
            });
        }
    }

    //#region METHODS
    onChange = onFieldChange;

    onSubmit() {
        let { mutableSettings } = { ...this.state };
        this.clearSaving()
        this.setState({ errors: {}, saving: true });
        this.save(mutableSettings);
    }

    clearSaving = () => this.setState({ saving: false });

    notifySuccess = () => toasty.success('Mutable Settings Saved', 'Mutable Settings saved successfully.');
    notifyError = (message) => toasty.error('Save Unsuccessful', message);
    handleSaveError = (err) => handleFormSaveError(this, err);

    notifyComplete = () => toasty.success('Evaluation Criteria Created', 'Sales Order Tracking Evaluation criteria generated');
    notifyCompleteWithErrors = () => toasty.success('Evaluation Criteria Partially Created', 'Sales Order Tracking Evaluation criteria generated with errors.');
    notifyIncomplete = () => toasty.error('Evluation Criteria Incomplete', 'Could not create Evaluatoin Criteria');

    displaySaveErrors = (response) => this.setState({ errorResponse: response });
    clearSaveErrors = () => this.setState({ errorResponse: {} });

    onCloseClicked = () => {
        this.props.history.push(`${AppNavPaths.Dashboard}`);
    }

    onCreateCriteria = async () => {
        this.clearSaving()
        this.setState({ errors: {}, saving: true });
        
        try {
            let response = await util.fetch.post(ApiRoutes.common.createCriteria(), {}, util.fetch.format.none);
            if (response.redirected) {
                window.location.href = response.url;
            } else if (!!response.ok) {

                let respData = await response.json();

                if (respData.Result == "SUCCESS") {

                    if ((respData.Data ?? []).length) {
                        let errResp = {
                            errors: respData.Data,
                            title: "Errors During Evaluation Criteria Creation"
                        }
                        this.displaySaveErrors(errResp);
                        this.notifyCompleteWithErrors();
                    } else {
                        this.notifyComplete();
                    }                    
                }

                if (respData.Result == "ERROR") {
                    let erresp = {
                        errors: [respData.Message],
                        title: "Failed To Create Evaluation Criteria"
                    }
                    this.displaySaveErrors(erresp);
                }
                                
            } else {
                this.displaySaveErrors(await response.json());
            }
        } catch (error) {
            this.notifyError(error.toString());
        } finally {
            this.clearSaving();
        }
    }

    save = async (mutableSettings) => {
        let { chosenWorkCenters, chosenTailWorkcenter } = { ...this.state };

        mutableSettings.changeOrderClearableWorkCenters = JSON.stringify(chosenWorkCenters);
        mutableSettings.shippingActivityWorkCenterId = chosenTailWorkcenter?.toString();

        try {
            let response = await util.fetch.put(ApiRoutes.common.mutableSettings(), mutableSettings, util.fetch.format.none);
            if (response.redirected) {
                window.location.href = response.url;
            } else if (!!response.ok) {
                this.notifySuccess();
                this.populateState();
            } else {
                this.displaySaveErrors(await response.json());
            }
        } catch (error) {
            this.notifyError(error.toString());
        } finally {
            this.clearSaving();
        }
    }

    //#endregion

    //#region RENDERING
    render() {
        const { loading, originalData, mutableSettings, perms, workcenters, tailWorkcenters } = { ...this.state };
        let { errorResponse, chosenWorkCenters, chosenTailWorkcenter } = { ...this.state };

        if (!!loading) {
            return (<LinearProgress variant="indeterminate" color="secondary" />);
        } else {
            const canEdit = !!perms.includes("role.edit");

            return (
                <Fragment>
                    <AppPageForm
                        formId={"mutableSettingsForm"}
                        formHeadingIcon={faTools}
                        formHeading={'Edit Mutable Settings'}
                        formName={"mutableSettingsForm"}
                        formRef={this.formRef}
                        onSubmit={this.onSubmit}
                        setIsValidated={(value) => { this.setState({ formValidated: value }) }}
                        isValidated={this.state.formValidated}
                        saving={this.state.saving}
                        errors={this.state.errors}
                        loading={this.state.loading}
                    >
                        <GroupedRow>
                            <FormGroupColumn>
                                <FormGroup>
                                    <FormLabel htmlFor="serialNumberSeed" text="Serial Number Seed" required={true} />
                                    <input id="serialNumberSeed"
                                        name="mutableSettings.serialNumberSeed"
                                        autoComplete="off"
                                        className="form-control"
                                        required
                                        disabled={!canEdit}
                                        onChange={!!canEdit? this.onChange : undefined}
                                        defaultValue={mutableSettings.serialNumberSeed} />
                                    <small className="invalid-feedback text-danger" hidden>Serial Number Seed is required.</small>
                                </FormGroup>
                                <FormGroup>
                                    <FormLabel htmlFor="serialNumberPrefix" text="Serial Number Prefix" required={true} />
                                    <input id="serialNumberPrefix"
                                        name="mutableSettings.serialNumberPrefix"
                                        autoComplete="off"
                                        className="form-control"
                                        required
                                        disabled={!canEdit}
                                        onChange={!!canEdit? this.onChange : undefined}
                                        defaultValue={mutableSettings.serialNumberPrefix} />
                                    <small className="invalid-feedback text-danger" hidden>Serial Number Prefix is required.</small>
                                </FormGroup>
                            </FormGroupColumn>
                            <FormGroupColumn>
                                <FormGroup>
                                    <FormLabel htmlFor="quoteNumberSeed" text="Quote Number Seed" required={true} />
                                    <input id="quoteNumberSeed"
                                        name="mutableSettings.quoteNumberSeed"
                                        autoComplete="off"
                                        className="form-control"
                                        required
                                        disabled={!canEdit}
                                        onChange={!!canEdit? this.onChange : undefined}
                                        defaultValue={mutableSettings.quoteNumberSeed} />
                                    <small className="invalid-feedback text-danger" hidden>Quote Number Seed is required.</small>
                                </FormGroup>
                                <FormGroup>
                                    <FormLabel htmlFor="quoteIgnoreItemIds" text="Quote Ignore Item Ids" required={true} />
                                    <input id="quoteIgnoreItemIds"
                                        name="mutableSettings.quoteIgnoreItemIds"
                                        autoComplete="off"
                                        className="form-control"
                                        required
                                        disabled={!canEdit}
                                        onChange={!!canEdit? this.onChange : undefined}
                                        defaultValue={mutableSettings.quoteIgnoreItemIds} />
                                    <small className="invalid-feedback text-danger" hidden>Quote Ignore Item Ids is required.</small>
                                </FormGroup>
                            </FormGroupColumn>
                            <FormGroupColumn>
                                <FormGroup>
                                    <FormLabel
                                        htmlFor="selectClearableWorkCenters"
                                        text="Clearable Work Centers"
                                        required={false}
                                        helpMessage="Work Centers which will be presented to users during a change order, that can be cleared as part of the change order"
                                    />
                                    <ValidatedSelect
                                        id="selectClearableWorkCenters"
                                        name="chosenTailWorkcenter"
                                        options={workcenters}
                                        isMulti
                                        value={chosenWorkCenters ?? ''}
                                        onChange={(selection) => {
                                            chosenWorkCenters = selection;
                                            this.setState({ chosenWorkCenters: chosenWorkCenters });
                                        }}
                                        validationMessage={""}
                                    />
                                </FormGroup>
                            </FormGroupColumn>
                            <FormGroupColumn>
                                <FormGroup>
                                    <FormLabel
                                        htmlFor="selectShippingActivityWorkCenter"
                                        text="Shipping Activity Work Center"
                                        required={false}
                                        helpMessage="The work center that will be associated with the shipping activity"
                                    />
                                    <ValidatedSelect
                                        id="selectShippingActivityWorkCenter"
                                        name="chosenWorkCenters"
                                        options={tailWorkcenters}
                                        isClearable={true}
                                        value={(tailWorkcenters ?? []).find(s => s.value == chosenTailWorkcenter) ?? ''}
                                        onChange={(selection) => {
                                            chosenTailWorkcenter = selection?.value;
                                            this.setState({ chosenTailWorkcenter: chosenTailWorkcenter });
                                        }}
                                        validationMessage={""}
                                    />
                                </FormGroup>
                            </FormGroupColumn>
                            <FormGroupColumn>
                                <Button
                                    size="sm"
                                    type="button"
                                    color="secondary"
                                    disabled={this.state.saving}
                                    name="mutableSettingsCreateCriteria"
                                    onClick={this.onCreateCriteria}
                                    className="ml-2">
                                    <FontAwesomeIcon
                                        className="mr-2"
                                        icon={faGears} />
                                    {"Create Sales Order Tracking Evaluation Criteria"}
                                </Button>
                            </FormGroupColumn>
                        </GroupedRow>
                        <FlexCenterRow className="mb-3">
                            {!!canEdit &&
                                <Button
                                    size="sm"
                                    type="submit"
                                    color="primary"
                                    disabled={this.state.saving}
                                    name="mutableSettingsForm">
                                    <FontAwesomeIcon
                                        className="mr-2"
                                        icon={faSave} />
                                    {'Save'}
                                </Button>
                            }
                            <Button
                                size="sm"
                                type="button"
                                color="secondary"
                                disabled={this.state.saving}
                                name="mutableSettingsFormClose"
                                onClick={this.onCloseClicked}
                                className="ml-2">
                                <FontAwesomeIcon
                                    className="mr-2"
                                    icon={faTimes} />
                                {"Close"}
                            </Button>
                        </FlexCenterRow>
                        <FlexRow>
                            <FormErrorResponseDisplay onClear={this.clearSaveErrors} response={errorResponse} />
                        </FlexRow>
                    </AppPageForm>
                    <Prompt
                        when={!this.state.saving && !isEqual(originalData, mutableSettings)}
                        message='You have unsaved changes, are you sure you want to leave?'
                    />
                </Fragment>
            );
        }

    }
    //#endregion
}

export default withRouter(MutableSettingsForm);