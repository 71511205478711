import React from 'react';
import { faChartLine } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LinearProgress } from '@material-ui/core';
import CommonContext, { ApiRoutes, AppNavPaths } from "../Common";
import {
    createDataSource,
    createGridOptions,
    DataGrid,
    IconCellRenderer,
    LinkCellRenderer,
    TextFilterDefaults,
    DateFilterDefaults,
    VariableLinkCellRenderer
} from '../common/dataGrid/DataGrid';
import DataGridSelectFilter from '../common/dataGrid/DataGridSelectFilter';
import DataGridSelectFloatingFilter from '../common/dataGrid/DataGridSelectFloatingFilter';
import DataGridToolbar from '../common/dataGrid/DataGridToolbar';
import { onFieldChange, PageHeading, PageWrap, SmallOutlineButton } from '../common/forms/FormElements';
import { util } from '../Util';
import { Can } from '../Can';
import authService from '../api-authorization/AuthorizeService';
import { Progress } from 'reactstrap';

export default class SalesOrderTrackingSearch extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            rowData: [],
            rowsSelected: [],
            isExporting: false,
            capImage: null            
        };

        this.onRowSelected = this.onRowSelected.bind(this);
        this.onChange = this.onChange.bind(this);
    }
    
    componentDidMount = () => {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount = async () => {
        await authService.unsubscribe(this._subscription);
    }

    onChange = onFieldChange;

    populateState = async () => {

        
        const customersPromise = util.fetch.js(ApiRoutes.typeAheads.apteanCustomers());

        const [customers] = await Promise.all([customersPromise]);

        await this.setState({ customers });

        const gridOptions = createGridOptions(this);
        gridOptions.cacheBlockSize = 30;

        gridOptions.components = {
            selectFilter: DataGridSelectFilter,
            selectFloatingFilter: DataGridSelectFloatingFilter,
            nameRenderer: LinkCellRenderer,
            iconRenderer: IconCellRenderer,
            variableLinkRenderer: VariableLinkCellRenderer
        };
        gridOptions.onRowSelected = this.onRowSelected;
        gridOptions.columnDefs = this.getColumnDefs();
        gridOptions.useLoading = true;
        gridOptions.loadingTemplate = '<span className="ag-overlay-loading-center "><i class="faSaveSpinner fa fa-md fa-circle-notch"></i> Loading ...</span>';

        const dataSource = createDataSource(ApiRoutes.SalesOrderTracking.search(), gridOptions);        
        
        this.setState({
            loading: false,
            gridOptions: gridOptions,
            dataSource: dataSource
        });        
    }

    getColumnDefs() {

        //https://www.ag-grid.com/documentation/javascript/column-definitions/

        let customerFilterParams = {
            suppressFilterButton: true,
            labelText: 'Filter by Customer',
            options: this.state.customers,
            optionsLabel: 'label',
            optionsValue: 'label',
        };

        let statusFilterParams = {
            suppressFilterButton: true,
            labelText: 'Filter by Status',
            options: [
                { label: 'In Progress', value: 'In Progress' },
                { label: 'Complete', value: 'Complete' }
            ],
            optionsLabel: 'label',
            optionsValue: 'value'            
        }

        const defs = [
            {
                headerName: "",
                valueGetter: "node.id",
                sortable: false,
                hide: true,
                flex: 1,
                maxWidth: 35,
                minWidth: 35,
                cellRenderer: this.indexCellRenderer
            },
            {
                colId: 'Id',
                minWidth: 75,
                maxWidth: 75,
                headerName: '',
                sortable: false,
                cellStyle: { color: "rgba(0,0,0,0)" },
                cellRenderer: 'iconRenderer',
                cellRendererParams: {
                    clicked: (id) => { this.props.history.push(`${AppNavPaths.SalesOrderTracking}/${id}`) },
                    idField: 'id',
                    iconClass: 'fa-edit'
                }
            },
            {
                colId: 'RequiredDate',
                headerName: 'Required Date',
                field: 'requiredDate',
                sortable: true,
                maxWidth: 120,
                minWidth: 120,
                sort: 'asc',
                filter: 'agDateColumnFilter',
                filterParams: DateFilterDefaults
            },
            {
                colId: 'SerialNumber',
                headerName: 'Serial#',
                field: 'serialNumber',
                sortable: true,
                flex: 1.5,
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true
                }
            },
            {
                colId: 'QuoteNumber',
                headerName: 'Quote#',
                field: 'quoteNumber',
                sortable: true,
                flex: 1.5,
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true
                }
            },
            {
                colId: 'SalesOrderNumber',
                headerName: 'SalesOrder#',
                field: 'salesOrderNumber',
                sortable: true,
                flex: 1.5,
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true
                }
            },
            {
                colId: 'Customer',
                headerName: 'Dealer/Customer',
                field: 'customer',
                sortable: true,
                flex: 1.5,
                filter: 'selectFilter',
                floatingFilter: true,
                filterParams: customerFilterParams,
                floatingFilterComponent: 'selectFloatingFilter',
                floatingFilterComponentParams: customerFilterParams
            },
            {
                colId: 'ItemName',
                headerName: 'Model',
                field: 'itemName',
                sortable: true,
                flex: 1.5,
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true
                }
            },
            {
                colId: 'PercentageComplete',
                headerName: 'Work Center Progress',
                sortable: false,
                maxWidth: 140,
                minWidth: 140,
                filter: 'selectFilter',
                floatingFilter: true,
                filterParams: statusFilterParams,
                floatingFilterComponent: 'selectFloatingFilter',
                floatingFilterComponentParams: statusFilterParams,
                cellRenderer: (params) => {
                    if (params.data) {
                        let prog = params.data?.percentageComplete ?? 0;
                        let color = "danger";
                        if (prog > 50) { color = "warning" }
                        if (prog == 100) { color = "success" }
                        return (
                            <Progress
                                className="my-2"
                                color={color}
                                value={prog}
                                style={{position: 'relative'}}
                            >
                                <small style={{color: 'black'}} className={"justify-content-center d-flex position-absolute w-100"}>{`${prog}%`}</small>
                            </Progress>
                        );
                    }

                    return null;
                }
            }
        ];

        return defs;
    }

    //https://stackoverflow.com/questions/44263350/count-number-of-selected-rows-in-ag-grid
    //Possibly use lodash dequeue?
    onRowSelected(e) {

        const rs = e.api.getSelectedRows();

        this.setState({
            rowsSelected: rs
        });
    }

    render() {
        const {
            rowData,
            gridOptions,
            loading
        } = this.state;

        if (!!loading) {
            return (<LinearProgress variant="indeterminate" color="secondary" />);
        } else {

            return (
                <CommonContext.Consumer>
                    {
                        value => {                            
                            return (
                                <PageWrap>
                                    <PageHeading>
                                        <FontAwesomeIcon icon={faChartLine} className="mr-2 text-muted" />
                                        <span>Order Tracking</span>
                                        <Can do="create" on="sales_order_tracking">
                                            <SmallOutlineButton onClick={() => { this.props.history.push(`${AppNavPaths.SalesOrderTrackingNew}/0`) }} className="float-right">
                                                <i className="fa fa-plus-square fa-lg" />
                                                <span className="ml-2 small-viewport-hide">
                                                    Add From Aptean
                                                </span>
                                            </SmallOutlineButton>
                                        </Can>
                                    </PageHeading>
                                    <DataGridToolbar
                                        entity="SalesOrderTracking"
                                        gridApi={this.state.gridApi}
                                        dataSource={this.state.dataSource}
                                        hideAdd={true}
                                        hideExcelButton={true}
                                        gridOptions={this.state.gridOptions}
                                        serverExport={{ apiPath: ApiRoutes.SalesOrderTracking.excelExport(), filePrefix: 'OrderTrackingSearch' }}
                                    />
                                    <DataGrid
                                        domLayout={"normal"}
                                        rowData={rowData}
                                        gridOptions={gridOptions}
                                        gridStatus={this.state.gridStatus}
                                    />
                                </PageWrap>
                            )
                        }
                    }
                </CommonContext.Consumer>
            )
        }
    }
}