import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LinearProgress } from '@material-ui/core';
import CommonContext, { ApiRoutes, AppNavPaths } from "../Common";
import {
    createDataSource,
    createGridOptions,
    DataGrid,
    IconCellRenderer,
    LinkCellRenderer,
    TextFilterDefaults,
    VariableLinkCellRenderer,
    DateFilterDefaults,
} from '../common/dataGrid/DataGrid';
import { Progress } from 'reactstrap';
import DataGridSelectFilter from '../common/dataGrid/DataGridSelectFilter';
import DataGridSelectFloatingFilter from '../common/dataGrid/DataGridSelectFloatingFilter';
import DataGridToolbar from '../common/dataGrid/DataGridToolbar';
import { onFieldChange, PageHeading, PageWrap, SmallButton } from '../common/forms/FormElements';
import { util } from '../Util';
import authService from '../api-authorization/AuthorizeService';

export default class WorkFlowWorkCenterGrid extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            rowData: [],
            rowsSelected: [],
            isExporting: false,
            workCenter: {}
        };

        this.onRowSelected = this.onRowSelected.bind(this);
        this.onChange = this.onChange.bind(this);
    }
    
    componentDidMount = () => {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount = async () => {
        await authService.unsubscribe(this._subscription);
    }

    onChange = onFieldChange;

    populateState = async () => {
        const isAuthenticated = await authService.isAuthenticated();

        if (!!isAuthenticated) {
            const { id } = { ...this.props.match.params };

            if (!!!id) {
                this.props.history.push(`${AppNavPaths.NoPermission}`);
            } else {
                var [workCenterResponse] = await Promise.all(
                    [util.fetch.get(ApiRoutes.workCenter.byId(id), util.fetch.format.none)]
                );

                //Handle any issues fetching data
                if (!workCenterResponse.ok) {

                    if (workCenterResponse.status === 401) {
                        this.props.history.push(`${AppNavPaths.NoPermission}`);
                    } else {
                        //Handle erroneous links entered by the user
                        if (workCenterResponse.status === 404) {
                            this.props.history.push(AppNavPaths.NotFound);
                        } else {
                            this.props.history.push(AppNavPaths.ServerError);
                        }
                    }
                } else {
                    let workCenter = await workCenterResponse.json();

                    const customersPromise = util.fetch.js(ApiRoutes.typeAheads.apteanCustomers());

                    const [customers] = await Promise.all([customersPromise]);

                    await this.setState({ customers });

                    const gridOptions = createGridOptions(this);

                    gridOptions.components = {
                        selectFilter: DataGridSelectFilter,
                        selectFloatingFilter: DataGridSelectFloatingFilter,
                        nameRenderer: LinkCellRenderer,
                        iconRenderer: IconCellRenderer,
                        variableLinkRenderer: VariableLinkCellRenderer
                    };
                    gridOptions.customParametersFunction = () => ({ WorkCenterId: parseInt(id) });
                    gridOptions.cacheBlockSize = 30;
                    gridOptions.onRowSelected = this.onRowSelected;
                    gridOptions.columnDefs = this.getColumnDefs();
                    gridOptions.useLoading = true;
                    gridOptions.loadingTemplate = '<span className="ag-overlay-loading-center "><i class="faSaveSpinner fa fa-md fa-circle-notch"></i> Loading ...</span>';

                    const dataSource = createDataSource(ApiRoutes.workCenter.workflow(), gridOptions);

                    this.setState({
                        loading: false,
                        gridOptions: gridOptions,
                        dataSource: dataSource,
                        workCenter: workCenter
                    });
                }
            }
        }
    }

    getColumnDefs() {

        //https://www.ag-grid.com/documentation/javascript/column-definitions/

        let customerFilterParams = {
            suppressFilterButton: true,
            labelText: 'Filter by Customer',
            options: this.state.customers,
            optionsLabel: 'label',
            optionsValue: 'value',
        }

        let statusFilterParams = {
            suppressFilterButton: true,
            labelText: 'Filter by Status',
            options: [
                { label: "Complete", value: "Complete" }
                , { label: "Not Started", value: "Not Started" }
                , { label: "In Progress", value: "In Progress" }
            ],
            optionsLabel: 'label',
            optionsValue: 'value',
        }

        const defs = [
            {
                headerName: "",
                valueGetter: "node.id",
                sortable: false,
                hide: true,
                flex: 1,
                maxWidth: 35,
                minWidth: 35,
                cellRenderer: this.indexCellRenderer
            },
            {
                colId: 'SalesOrderTracking_Id',
                minWidth: 75,
                maxWidth: 75,
                headerName: '',
                sortable: false,
                cellStyle: { color: "rgba(0,0,0,0)" },
                cellRenderer: (params) => {
                    if (params.data) {

                        return (
                            <SmallButton
                                type="button"
                                disabled={!!this.props.formIsOpen}
                                onClick={() => this.props.history.push(`${AppNavPaths.WorkCenterWorkFlow}/${params.data.workCenter_Id}/${params.data.salesOrderTracking_Id}`)}
                            >
                                <i className={`fa fa-eye fa-md mr-2`} />
                            </SmallButton>
                        );
                    }

                    return null;
                }
            },
            {
                colId: 'RequiredDate',
                headerName: 'Required Date',
                field: 'requiredDate',
                sortable: true,
                maxWidth: 120,
                minWidth: 120,
                sort: 'asc',
                filter: 'agDateColumnFilter',
                filterParams: DateFilterDefaults
                , cellRenderer: (params) => {
                    if (params.data) {
                        if (params.data.requiredDate) {

                            let reqDate = new Date(params.data.requiredDate);

                            if (reqDate.toString() != "Invalid Date") {

                                return reqDate.toLocaleDateString('en-US');
                            }
                        }
                    }
                    return "Not Supplied";
                }
            },
            {
                colId: 'SerialNumber',
                headerName: 'Serial#',
                field: 'serialNumber',
                sortable: true,
                sort: { direction: 'asc', priority: 0 },
                flex: 1.5,
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true
                }
            },
            {
                colId: 'ItemName',
                headerName: 'Model',
                field: 'itemName',
                sortable: true,
                flex: 1.5,
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true
                }
            },
            {
                colId: 'Customer',
                headerName: 'Dealer/Customer',
                field: 'customer',
                sortable: true,
                flex: 1.5,
                filter: 'selectFilter',
                floatingFilter: true,
                filterParams: customerFilterParams,
                floatingFilterComponent: 'selectFloatingFilter',
                floatingFilterComponentParams: customerFilterParams
            },
            {
                colId: 'Status',
                headerName: 'Status',
                field: 'status',
                sortable: true,
                filter: 'selectFilter',
                floatingFilter: true,
                filterParams: statusFilterParams,
                floatingFilterComponent: 'selectFloatingFilter',
                floatingFilterComponentParams: statusFilterParams
            },
            {
                colId: 'WorkCenterProgress',
                headerName: 'Progress',
                sortable: false,
                maxWidth: 140,
                minWidth: 140,
                floatingFilterComponentParams: {
                    suppressFilterButton: true
                },
                cellRenderer: (params) => {
                    if (params.data) {
                        let completeCount = (params.data?.completedCount ?? 0);
                        let totalCount = (params.data?.formCount ?? 0);

                        //If the workcenter has no forms, then there is no way to have a progress
                        if (totalCount == 0) {
                            return (
                                <span>N/A</span>
                            );
                        }

                        if (completeCount > totalCount) {
                            completeCount = totalCount;
                        }

                        let prog = Math.round((completeCount / totalCount) * 100);
                        let color = "danger";
                        if (prog > 50) { color = "warning" }
                        if (prog == 100) { color = "success" }
                        return (
                            <Progress
                                className="my-2"
                                color={color}
                                value={prog}
                                style={{ position: 'relative' }}
                            >
                                <small style={{ color: prog > 49 ? 'white' : 'black' }} className={"justify-content-center d-flex position-absolute w-100"}>{`${prog}%`}</small>
                            </Progress>
                        );
                    }

                    return (
                        <span>N/A</span>
                    );
                }
            }
        ];

        return defs;
    }

    //https://stackoverflow.com/questions/44263350/count-number-of-selected-rows-in-ag-grid
    //Possibly use lodash dequeue?
    onRowSelected(e) {

        const rs = e.api.getSelectedRows();

        this.setState({
            rowsSelected: rs
        });
    }

    openSalesOrderTrackingSlideOut = async (id, addBy) => {
        await this.context.setFormOpened(true);
        this.salesOrderTrackingSlideoutRef.current.open(id, addBy);
    }

    onSalesOrderTrackingSlideOutClosed = async () => {
        await this.context.setFormOpened(false);
        this.setState({ selectedRow: null });
        this.state.gridOptions.refresh();
    }

    render() {
        const {
            rowData,
            gridOptions,
            workCenter,
            loading
        } = this.state;

        if (!!loading) {
            return (<LinearProgress variant="indeterminate" color="secondary" />);
        } else {
            return (
                <CommonContext.Consumer>
                    {
                        value => {                            
                            return (
                                <PageWrap>
                                    <PageHeading>
                                        <FontAwesomeIcon icon={workCenter.iconName} className="mr-2 text-muted" />
                                        <span>{workCenter.name}</span>
                                    </PageHeading>
                                    <DataGridToolbar
                                        entity={workCenter.name}
                                        gridApi={this.state.gridApi}
                                        dataSource={this.state.dataSource}
                                        hideAdd={true}
                                        hideExcelButton={true}
                                        gridOptions={this.state.gridOptions}
                                        serverExport={{ apiPath: ApiRoutes.workCenter.workflowExport(), filePrefix: 'WorkCenterWorkFlow' }}
                                    />
                                    <DataGrid
                                        domLayout={"normal"}
                                        rowData={rowData}
                                        gridOptions={gridOptions}
                                        gridStatus={this.state.gridStatus}
                                    />
                                </PageWrap>
                            )
                        }
                    }
                </CommonContext.Consumer>
            )
        }
    }
}